/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Urlbite API
 * Api for Urlbite app
 * OpenAPI spec version: 1.0
 */
import { faker } from '@faker-js/faker';
import { delay, http, HttpResponse } from 'msw';

import type { Url } from './urlbite.api.schemas';

export const getGetHelloResponseMock = (): string => faker.word.sample();

export const getGetUserIpResponseMock = (): string => faker.word.sample();

export const getFindAllUrlsResponseMock = (): Url[] =>
  Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() => ({
    createdAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
    id: faker.word.sample(),
    originalUrl: faker.internet.url(),
    shortUrl: faker.internet.url(),
    uniqueVisits: faker.number.int({ min: undefined, max: undefined }),
    updatedAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
    urlId: faker.word.sample(),
    visitors: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() => ({
      createdAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
      id: faker.word.sample(),
      meta: { browser: faker.word.sample(), os: faker.word.sample() },
      updatedAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
      url: faker.word.sample(),
    })),
    visits: faker.number.int({ min: undefined, max: undefined }),
  }));

export const getCreateUrlResponseMock = (overrideResponse: Partial<Url> = {}): Url => ({
  createdAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
  id: faker.word.sample(),
  originalUrl: faker.internet.url(),
  shortUrl: faker.internet.url(),
  uniqueVisits: faker.number.int({ min: undefined, max: undefined }),
  updatedAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
  urlId: faker.word.sample(),
  visitors: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() => ({
    createdAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
    id: faker.word.sample(),
    meta: { browser: faker.word.sample(), os: faker.word.sample() },
    updatedAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
    url: faker.word.sample(),
  })),
  visits: faker.number.int({ min: undefined, max: undefined }),
  ...overrideResponse,
});

export const getFindUrlResponseMock = (overrideResponse: Partial<Url> = {}): Url => ({
  createdAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
  id: faker.word.sample(),
  originalUrl: faker.internet.url(),
  shortUrl: faker.internet.url(),
  uniqueVisits: faker.number.int({ min: undefined, max: undefined }),
  updatedAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
  urlId: faker.word.sample(),
  visitors: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() => ({
    createdAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
    id: faker.word.sample(),
    meta: { browser: faker.word.sample(), os: faker.word.sample() },
    updatedAt: faker.helpers.arrayElement([`${faker.date.past().toISOString().split('.')[0]}Z`, undefined]),
    url: faker.word.sample(),
  })),
  visits: faker.number.int({ min: undefined, max: undefined }),
  ...overrideResponse,
});

export const getGetHelloMockHandler = (
  overrideResponse?: string | ((info: Parameters<Parameters<typeof http.get>[1]>[0]) => Promise<string> | string),
) => {
  return http.get('*/api/v1', async (info) => {
    await delay(1000);
    return new HttpResponse(
      JSON.stringify(
        overrideResponse !== undefined
          ? typeof overrideResponse === 'function'
            ? await overrideResponse(info)
            : overrideResponse
          : getGetHelloResponseMock(),
      ),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  });
};

export const getGetUserIpMockHandler = (
  overrideResponse?: string | ((info: Parameters<Parameters<typeof http.get>[1]>[0]) => Promise<string> | string),
) => {
  return http.get('*/api/v1/ip', async (info) => {
    await delay(1000);
    return new HttpResponse(
      JSON.stringify(
        overrideResponse !== undefined
          ? typeof overrideResponse === 'function'
            ? await overrideResponse(info)
            : overrideResponse
          : getGetUserIpResponseMock(),
      ),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  });
};

export const getFindAllUrlsMockHandler = (
  overrideResponse?: Url[] | ((info: Parameters<Parameters<typeof http.get>[1]>[0]) => Promise<Url[]> | Url[]),
) => {
  return http.get('*/api/v1/url', async (info) => {
    await delay(1000);
    return new HttpResponse(
      JSON.stringify(
        overrideResponse !== undefined
          ? typeof overrideResponse === 'function'
            ? await overrideResponse(info)
            : overrideResponse
          : getFindAllUrlsResponseMock(),
      ),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  });
};

export const getCreateUrlMockHandler = (
  overrideResponse?: Url | ((info: Parameters<Parameters<typeof http.post>[1]>[0]) => Promise<Url> | Url),
) => {
  return http.post('*/api/v1/url', async (info) => {
    await delay(1000);
    return new HttpResponse(
      JSON.stringify(
        overrideResponse !== undefined
          ? typeof overrideResponse === 'function'
            ? await overrideResponse(info)
            : overrideResponse
          : getCreateUrlResponseMock(),
      ),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  });
};

export const getRedirectToUrlMockHandler = () => {
  return http.get('*/api/v1/url/:urlId', async () => {
    await delay(1000);
    return new HttpResponse(null, {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  });
};

export const getFindUrlMockHandler = (
  overrideResponse?: Url | ((info: Parameters<Parameters<typeof http.get>[1]>[0]) => Promise<Url> | Url),
) => {
  return http.get('*/api/v1/url/:urlId/info', async (info) => {
    await delay(1000);
    return new HttpResponse(
      JSON.stringify(
        overrideResponse !== undefined
          ? typeof overrideResponse === 'function'
            ? await overrideResponse(info)
            : overrideResponse
          : getFindUrlResponseMock(),
      ),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  });
};
export const getUrlbiteAPIMock = () => [
  getGetHelloMockHandler(),
  getGetUserIpMockHandler(),
  getFindAllUrlsMockHandler(),
  getCreateUrlMockHandler(),
  getRedirectToUrlMockHandler(),
  getFindUrlMockHandler(),
];
