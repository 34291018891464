import { createMutation, createQuery } from '@farfetched/core';
import { createEffect } from 'effector';

import { NetworkExceptionError } from '../exceptions';
import { createUrl, CreateUrlDto, findUrl, Url } from '../gen';

export const createShortUrlFx = createEffect<CreateUrlDto, Url, NetworkExceptionError>(
  async (createUrlDto: CreateUrlDto) => {
    const shortenedUrl = await createUrl(createUrlDto);

    return shortenedUrl;
  },
);

export const createShortUrlMutation = createMutation({
  handler: createShortUrlFx,
});

export const findUrlByIdFx = createEffect<string, Url, NetworkExceptionError>(async (urlId) => {
  const url = await findUrl(urlId);

  return url;
});

export const findUrlByIdQuery = createQuery({
  handler: findUrlByIdFx,
});
