/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Urlbite API
 * Api for Urlbite app
 * OpenAPI spec version: 1.0
 */
import { z as zod } from 'zod';

/**
 * Get greeting
 * @summary Get greeting
 */
export const getHelloResponse = zod.string();

/**
 * Get user IP
 * @summary Get user IP
 */
export const getUserIpResponse = zod.string();

/**
 * Find all urls
 * @summary Find all urls
 */
export const findAllUrlsResponseItem = zod.object({
  id: zod.string(),
  createdAt: zod.string().datetime().optional(),
  updatedAt: zod.string().datetime().optional(),
  urlId: zod.string(),
  originalUrl: zod.string().url(),
  shortUrl: zod.string().url(),
  visitors: zod.array(
    zod.object({
      id: zod.string(),
      createdAt: zod.string().datetime().optional(),
      updatedAt: zod.string().datetime().optional(),
      url: zod.string(),
      meta: zod.object({
        browser: zod.string(),
        os: zod.string(),
      }),
    }),
  ),
  visits: zod.number(),
  uniqueVisits: zod.number(),
});
export const findAllUrlsResponse = zod.array(findAllUrlsResponseItem);

/**
 * Create shortened url
 * @summary Create shortened url
 */
export const createUrlBody = zod.object({
  url: zod.string().url(),
});

export const createUrlResponse = zod.object({
  id: zod.string(),
  createdAt: zod.string().datetime().optional(),
  updatedAt: zod.string().datetime().optional(),
  urlId: zod.string(),
  originalUrl: zod.string().url(),
  shortUrl: zod.string().url(),
  visitors: zod.array(
    zod.object({
      id: zod.string(),
      createdAt: zod.string().datetime().optional(),
      updatedAt: zod.string().datetime().optional(),
      url: zod.string(),
      meta: zod.object({
        browser: zod.string(),
        os: zod.string(),
      }),
    }),
  ),
  visits: zod.number(),
  uniqueVisits: zod.number(),
});

/**
 * Redirect from short url to original url
 * @summary Redirect from short url to original url
 */
export const redirectToUrlParams = zod.object({
  urlId: zod.string(),
});

/**
 * Finds shortened url
 * @summary Finds shortened url
 */
export const findUrlParams = zod.object({
  urlId: zod.string(),
});

export const findUrlResponse = zod.object({
  id: zod.string(),
  createdAt: zod.string().datetime().optional(),
  updatedAt: zod.string().datetime().optional(),
  urlId: zod.string(),
  originalUrl: zod.string().url(),
  shortUrl: zod.string().url(),
  visitors: zod.array(
    zod.object({
      id: zod.string(),
      createdAt: zod.string().datetime().optional(),
      updatedAt: zod.string().datetime().optional(),
      url: zod.string(),
      meta: zod.object({
        browser: zod.string(),
        os: zod.string(),
      }),
    }),
  ),
  visits: zod.number(),
  uniqueVisits: zod.number(),
});
