/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Urlbite API
 * Api for Urlbite app
 * OpenAPI spec version: 1.0
 */
import { httpClient, type BodyType } from '../api';
import type { CreateUrlDto, Url } from './urlbite.api.schemas';

/**
 * Get greeting
 * @summary Get greeting
 */
export const getHello = () => {
  return httpClient<string>({ url: `https://api.urlbite.dev/api/v1`, method: 'GET' });
};

/**
 * Get user IP
 * @summary Get user IP
 */
export const getUserIp = () => {
  return httpClient<string>({ url: `https://api.urlbite.dev/api/v1/ip`, method: 'GET' });
};

/**
 * Find all urls
 * @summary Find all urls
 */
export const findAllUrls = () => {
  return httpClient<Url[]>({ url: `https://api.urlbite.dev/api/v1/url`, method: 'GET' });
};

/**
 * Create shortened url
 * @summary Create shortened url
 */
export const createUrl = (createUrlDto: BodyType<CreateUrlDto>) => {
  return httpClient<Url>({
    url: `https://api.urlbite.dev/api/v1/url`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUrlDto,
  });
};

/**
 * Redirect from short url to original url
 * @summary Redirect from short url to original url
 */
export const redirectToUrl = (urlId: string) => {
  return httpClient<unknown>({ url: `https://api.urlbite.dev/api/v1/url/${urlId}`, method: 'GET' });
};

/**
 * Finds shortened url
 * @summary Finds shortened url
 */
export const findUrl = (urlId: string) => {
  return httpClient<Url>({ url: `https://api.urlbite.dev/api/v1/url/${urlId}/info`, method: 'GET' });
};

export type GetHelloResult = NonNullable<Awaited<ReturnType<typeof getHello>>>;
export type GetUserIpResult = NonNullable<Awaited<ReturnType<typeof getUserIp>>>;
export type FindAllUrlsResult = NonNullable<Awaited<ReturnType<typeof findAllUrls>>>;
export type CreateUrlResult = NonNullable<Awaited<ReturnType<typeof createUrl>>>;
export type RedirectToUrlResult = NonNullable<Awaited<ReturnType<typeof redirectToUrl>>>;
export type FindUrlResult = NonNullable<Awaited<ReturnType<typeof findUrl>>>;
